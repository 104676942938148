<template>
   <ul class="nav flex-column p-0">
      <router-link
         v-for="(item, index) in items"
         :key="index"
         :to="item.to"
         as="li"
         class="nav-item text-decoration-none mt-4"
      >
         <AmcNavItem :item="item" />
      </router-link>
   </ul>
</template>

<script>
export default {
   name: 'AmcNav',
   props: {
      items: {
         type: [Array, Object],
         default: () => [],
      },
   },
};
</script>

<style scoped></style>
