import { render, staticRenderFns } from "./AmcNav.vue?vue&type=template&id=1ba56622&scoped=true&"
import script from "./AmcNav.vue?vue&type=script&lang=js&"
export * from "./AmcNav.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ba56622",
  null
  
)

export default component.exports